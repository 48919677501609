<template>
  <div class="follow-info">
    <van-form @submit="onSubmitFollowInfo" :show-error="false">
      <van-field label="姓名" :value="followInfoData.username" disabled />
      <van-field label="电话" :value="followInfoData.phone" disabled />
      <van-field
        label-width="120px"
        label="跟进结果"
        required
        :border="false"
        disabled
      />
      <van-button
        :class="
          followResonIndex === index
            ? 'stayin-reson-active'
            : 'stayin-reson-botton'
        "
        round
        size="small"
        v-for="(item, index) in stayinReson"
        :key="index"
        @click.prevent="getStayinReson(index)"
        >{{ item.name }}</van-button
      >
      <van-field
        v-model="followInfoData.resons"
        rows="3"
        autosize
        type="textarea"
        maxlength="20"
        placeholder="请输入文字"
        show-word-limit
        v-if="followResonIndex === stayinReson.length - 1"
      />
      <van-field
        readonly
        clickable
        name="calendar"
        :value="followInfoData.date"
        label="下次跟进时间"
        placeholder="选择日期时间"
        required
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择日期时间' }]"
      />
      <van-field
        v-model="followInfoData.note"
        rows="2"
        autosize
        label="备注"
        label-width="35px"
        type="textarea"
        maxlength="30"
        placeholder="请输入备注"
        show-word-limit
      />
      <div style="margin: 16px; margin-top: 40px">
        <van-button
          block
          type="info"
          native-type="submit"
          :loading="btn_loading"
          loading-text="确认"
          >确认</van-button
        >
      </div>
    </van-form>
    <!-- 下次跟进时间 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmPicker"
        @cancel="showPicker = false"
      >
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import { contactlogs } from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      btn_loading: false,
      customerId: "",
      // 跟进信息表单数据
      followInfoData: {
        username: "",
        phone: "",
        reson: "",
        resons: "",
        date: "",
        note: "",
      },
      // 不能入住原因判断
      followResonIndex: "",
      // 不能入住的原因
      stayinReson: [
        {
          id: 1,
          name: "长者不满足入住要求",
        },
        {
          id: 2,
          name: "没有合适的套餐",
        },
        {
          id: 3,
          name: "价格太贵",
        },
        {
          id: 4,
          name: "距离太远",
        },
        {
          id: 5,
          name: "自定义原因",
        },
      ],
      // 时间列表显示与隐藏
      showPicker: false,
      minDate: new Date(),
      min_date: new Date(2020, 8, 9),
      currentDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };
  },
  created() {
    this.followInfoData.username = this.$route.query.username;
    this.followInfoData.phone = this.$route.query.phone;
    this.customerId = this.$route.query.id;
  },
  methods: {
    // 提交
    onSubmitFollowInfo() {
      let follow = this.$route.query.follow;
      if (this.followResonIndex === this.stayinReson.length - 1) {
        this.followInfoData.reson = this.followInfoData.resons;
      } else {
        this.followInfoData.reson === this.followInfoData.reson;
      }
      if (
        this.followInfoData.reson === null ||
        this.followInfoData.reson === ""
      ) {
        this.$toast("请选择跟进结果");
      } else {
        this.btn_loading = true;
        contactlogs({
          id: this.customerId,
          desc: this.followInfoData.reson,
          time: this.followInfoData.date,
          remarkdesc: this.followInfoData.note,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.data);
              this.btn_loading = false;
              if (follow) {
                this.$router.push({ path: "/myFollow" });
              } else {
                this.$router.push({ path: "/myClient" });
              }
            }
          })
          .catch(() => {
            this.btn_loading = false;
          });
      }
    },
    // 获取不能入住原因
    getStayinReson(index) {
      this.stayinReson.map((item, key) => {
        if (index === key) {
          this.followInfoData.reson = item.name;
          this.followResonIndex = key;
        }
      });
    },
    // 记录客户信息下次跟进日期选择
    onConfirmPicker(time) {
      this.followInfoData.date = moment(time).format("YYYY-MM-DD HH:mm:ss");
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-info {
  margin-top: 22px;
  background-color: #fff;
}
.stayin-reson-botton {
  background-color: #fff;
  border: 1px solid #eaeaea;
  color: #9a9a9a;
  margin: 8px;
}
.stayin-reson-active {
  background-color: #fff;
  border: 1px solid #f3973b;
  color: #f3973b;
  margin: 8px;
}
/deep/.van-button--info,
/deep/.van-button--primary {
  background-color: #f3973b;
  border: #f3973b;
}
::v-deep .van-field--disabled .van-field__label {
  color: #646566;
}
</style>